<template>
  <div class="wrapper">
    <BaseAlert
      v-if="!modelId"
      type="info"
      text="Перед добавлением файлов к сущности сохраните ее"
    />
    <div
      v-else
      :class="multiple ? 'content-files' : 'content-file'"
      class="pl-1 d-flex align-center flex-column"
    >
      <BaseUploaderFile
        class="pa-1 pb-0 pt-3"
        v-if="defer(1)"
        v-show="!disabled"
        @load="onLoadFile"
        :accept-types="acceptTypes"
        single-line
        :multiple="multiple"
        :id="idUpload"
        :disabled="isLoading"
        :title="title"
      />
      <Limit :max-file-size="bytesToSize(maxFileSize, ' ')" v-if="defer(1) && !disabled" />

      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        v-if="isLoading"
        style="width: 100%"
        class="mb-3"
        :class="{'px-0': $vuetify.breakpoint.xsOnly }"
      ></v-progress-circular>
      <NoDocs v-if="files.length === 0 && !isLoading"/>
      <div
        v-if="defer(2)"
        class="d-flex justify-center flex-wrap images mb-1 mr-1"
        style="gap: 15px"
      >
        <Card
          v-if="files.length > 0 && defer(2)"
          v-for="item in files"
          :key="item.id + '' + files.length"
          max-width="250"
          width="250"
          max-height="250"
          class="pa-2 align-center d-flex flex-column"
        >
          <template #content>
            <div class="d-flex flex-column">
            <div class="pl-1 pt-1 d-flex justify-space-between flex-row align-top">
              <p class="ma-0 small-text">Создано: {{ timestampToDate(item.file.created_at) }}</p>
              <v-icon
                @click="handleDelete(item.file.id)"
                :disabled="isLoading"
                color="red"
                class="pr-1"
                v-show="!disabled && multiple"
              >mdi-delete-outline
              </v-icon>
            </div>
            <div class="d-flex justify-center flex-column align-center pb-1 pt-1">
              <v-btn
                text
                target="_blank"
                :disabled="isLoading"
                :href="getPath(item)"
                class="btn_link"
                height="114"
                width="100"
              >
                <v-icon
                  color="#64C7FF"
                  size="100"
                >
                  mdi-file
                </v-icon>
                <p class="long-text grey--text">{{ item.file.name }}</p>
              </v-btn>
            </div>
            <div
              class="d-flex flex-column"
              style="height: 85px"
              :class="!item.file.description ? 'justify-center' : 'justify-space-between'"
            >
              <div
                class="d-flex flex-row align-center justify-center mr-2 ml-2 description-container"
                v-if="withDescription"
                style="min-width: 220px"
              >
                <BaseBtn
                  is-small-icon
                  is-type-icon
                  color="#64C7FF"
                  :icon="item.isEditModeDescription ? 'mdi-content-save' : 'mdi-pencil-outline'"
                  @click="handleEditDescr(item)"
                  :disabled="disabled"
                />
                <p
                  v-if="!item.isEditModeDescription"
                  class="ma-0 description-docs mr-2"
                  @click="handleEditDescr(item)"
                >
                  {{ localDescr(item.file) | truncate(25, '...') }}
                </p>
                <v-text-field
                  v-model="item.file.description"
                  label="Описание"
                  outlined
                  dense
                  hide-details
                  class="pa-0 description-input"
                  v-if="item.isEditModeDescription"
                ></v-text-field>
              </div>

              <span
                v-if="item.file.description"
                class="align-self-start small-text pl-3"
              >Дата описания: {{ item.file.description_date }}</span>
            </div>

            </div>
          </template>
        </Card>
      </div>
      <BaseDialog
        role="delete"
        label="Удалить файл?"
        v-model="isDeleteDialog"
        @confirm="deleteFile"
        :max-width="350"
        is-confirm-btn
        is-cancel-btn
      />
    </div>
  </div>
</template>

<script>
import defer from '@/components/mixins/defer'
import formatters from '@/components/mixins/formatters'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseUploaderFile from '@/components/base/BaseUploaderFile'
import BaseDialog from '@/components/base/BaseDialog'
import watchValue from '@/components/mixins/watchValue'
import BaseBtn from '@/components/base/UI/BaseBtn'
import baseDocuments from '@/components/mixins/commonForLayouts/baseDocuments'
import NoDocs from '@/components/base/BaseDocuments/NoDocs'
import Card from '@/components/base/UI/Card'
import Limit from '@/components/base/BaseDocuments/Limit'
//note: компонент переделан. Теперь @input будет возвращать целиком детали. обязательно нужно передать nameObjectDoc('passportFiles' или 'objectFiles')
//note: есть отдельно проп valueDocs(объект с документами), который будет использоваться вместо деталей(value), тогда nameObjectDoc не нужно передавать
export default {
  name: 'BaseDocuments',
  components: { Limit, Card, NoDocs, BaseBtn, BaseUploaderFile, BaseAlert, BaseDialog },
  mixins: [defer, formatters, watchValue, baseDocuments]
}
</script>

<style scoped>
  .wrapper {
    height: 100%;
  }
  .content-files {
    height: calc(100% - 75px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .content-file {
    height: 140px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .long-text {
    width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }
  /deep/ .btn_link .v-btn__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 180px;
    width: 180px;
  }
  .btn_link {
    max-width: 180px;
  }
  .description-docs {
    max-height: 40px;
    line-clamp: 2; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 2;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
